/*
**
** @Description: 首页
** @Path: '/'
*/
<template>
  <div style="padding-bottom: 28vw;">
    <img src="~@/assets/images/common/banner.png" class="loginBg" />
    <div class="pl30 pr30 pt30 pb30">
      <div class="fs38 f600 c-333 pb20">
        保障计划
      </div>
      <div>
        <van-tabs type="card" v-model="active1">
          <van-tab title="基础版1">
            <div class="tabInner">
              <div class="tabCont">
                <div class="tabLeft fs28 c-666">车上人员意外身故、残疾</div>
                <div class="tabRight fs28 c-333 f500">5万</div>
              </div>
              <div class="tabCont">
                <div class="tabLeft fs28 c-666">车上人员意外医疗费用</div>
                <div class="tabRight fs28 c-333 f500">5000元</div>
              </div>
              <div class="tabCont">
                <div class="tabLeft fs28 c-666">第三者身故、伤残</div>
                <div class="tabRight fs28 c-333 f500">5万</div>
              </div>
              <div class="tabCont">
                <div class="tabLeft fs28 c-666">第三者医疗费用</div>
                <div class="tabRight fs28 c-333 f500">5000元</div>
              </div>
              <div class="tabCont">
                <div class="tabLeft fs28 c-666">第三者财产损失</div>
                <div class="tabRight fs28 c-333 f500">2000元</div>
              </div>
              <div class="borderTop tabCont pt20">
                <div class="tabLeft fs28 c-eee">保费</div>
                <div class="tabRight fs38 c-red fBlod">80元</div>
              </div>
            </div>
          </van-tab>
          <van-tab title="基础版2">
            <div class="tabInner">
              <div class="tabCont">
                <div class="tabLeft fs28 c-666">车上人员意外身故、残疾</div>
                <div class="tabRight fs28 c-333 f500">10万</div>
              </div>
              <div class="tabCont">
                <div class="tabLeft fs28 c-666">车上人员意外医疗费用</div>
                <div class="tabRight fs28 c-333 f500">1万</div>
              </div>
              <div class="tabCont">
                <div class="tabLeft fs28 c-666">第三者身故、伤残</div>
                <div class="tabRight fs28 c-333 f500">10万</div>
              </div>
              <div class="tabCont">
                <div class="tabLeft fs28 c-666">第三者医疗费用</div>
                <div class="tabRight fs28 c-333 f500">1万</div>
              </div>
              <div class="tabCont">
                <div class="tabLeft fs28 c-666">第三者财产损失</div>
                <div class="tabRight fs28 c-333 f500">5000元</div>
              </div>
              <div class="borderTop tabCont pt20">
                <div class="tabLeft fs28 c-eee">保费</div>
                <div class="tabRight fs38 c-red fBlod">140元</div>
              </div>
            </div>
          </van-tab>
        </van-tabs>
      </div>
      <div class="tips mt30">
        <div class="tabCont">
          <div class="tabLeft fs28 c-666">保险时间：</div>
          <div class="tabRight fs28 c-666">1年；</div>
        </div>
        <div class="tabCont">
          <div class="tabLeft fs28 c-666">投保年龄：</div>
          <div class="tabRight fs28 c-666">18-75岁；</div>
        </div>
        <div class="tabCont">
          <div class="tabLeft fs28 c-666">承保范围：</div>
          <div class="tabRight fs28 c-666">商业运营电动车，不在承保范围之内；</div>
        </div>
      </div>
    </div>
    <div class="conts">
      <van-tabs v-model="active">
        <van-tab title="产品说明">
          <div class="pt30 pl30 pr30">
            <img src="~@/assets/images/common/1.png" class="imgs" />
            <div class="pt10">
              <img src="~@/assets/images/common/2.png" class="imgs" />
            </div>
          </div>
        </van-tab>
        <van-tab title="理赔流程">
          <div class="pt30 pl30 pr30">
            <img src="~@/assets/images/common/1.png" class="imgs" />
            <div class="pt10">
              <img src="~@/assets/images/common/2.png" class="imgs" />
            </div>
          </div>
        </van-tab>
      </van-tabs>
    </div>
    <div class="popBtn" style="padding: 8vw 0;">
      <div class="btn btnOne fs30 c-666">
        <router-link to="/login" class="block textCenter">登录</router-link>
      </div>
      <div class="btn btnTwo fs30 ml40">
        <router-link to="/car" class="block textCenter">上牌</router-link>
      </div>
    </div>
    <div class="btns">
      <div>
        <div class="fs28 c-fff">合计保费</div>
        <dvi class="fs38 c-fff pt5">¥{{ active1 ? '140' : '80' }}元</dvi>
      </div>
      <div class="buyBtn fs32 f500" @click="show = true">
        立即投保
      </div>
    </div>
    <van-popup v-model="show" round position="bottom" :style="{ height: '35%' }">
      <div class="pt30 pl20 pr20">
        <div class="textCenter fs34 f600">温馨提示，您即将进入投保流程</div>
        <div class="touTip fs28 c-999 pt20">
          本产品由国任财产保险股份有限公司承保，深圳市中安信保险经纪有限公司销售，请仔细阅读保险条款、投保须知等内容，为了保障你的权益，你在销售页面的操作将会被记录并加密储存。
        </div>
        <div class="popBtn" style="padding-top: 10vw;">
          <div class="btn btnOne fs30 c-666" @click="show = false">取消</div>
          <div class="btn btnTwo fs30 ml40">
            <router-link to="/pay" class="">知道并同意</router-link>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        show: false,
        active: 0,
        active1: 0,
        formData: {}
      }
    },
    methods: {
      async onSubmit () {
        // 解码 window.atob
        console.log(111)
      },
    }
  }
</script>

<style lang="less" scoped>
  .touTip {
    text-indent: 2em;
  }
  .popBtn {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .btn {
    width: 40vw;
    border: 1px solid #eee;
    text-align: center;
    padding: 3vw 0;
    border-radius: 50px;
    a {
      color: #666;
    }
  }
  .btnTwo {
    background: #ffc107;
    border-color: #ffc107;
    a {
      color: #412C26;
    }
  }
  .btns {
    position: fixed;
    width: 94vw;
    bottom: 3vw;
    left: 3vw;
    background: #412C26;
    border-radius: 50px;
    padding: 3vw 4vw 3vw 8vw;
    display: flex;
    justify-content: space-between;
  }
  .buyBtn {
    padding: 3vw 8vw;
    background: #ffc107;
    color: #412C26;
    border-radius: 50px;
  }
  .loginBg {
    width: 100%;
    display: block;
  }
  .imgs {
    display: block;
    width: 100%;
  }
  .c-eee {
    color: #cbcbcb;
  }
  .c-red {
    color: #dc0000;
  }
  .conts {
    border-top: 5vw solid #eee;
  }
  .tips {
    background: #e9e9e9;
    border-radius: 3vw;
    padding: 4vw 3vw;
    padding-bottom: 0;
    .tabCont {
      justify-content: start;
    }
  }
  .tabInner {
    padding: 3vw;
    padding-bottom: 0;
    border: 1px solid #eee;
    border-radius: 0 0 3vw 3vw;
  }
  .borderTop{
    border-top: 1px solid #eee;
  }
  .tabCont {
    display: flex;
    justify-content: space-between;
    padding-bottom: 4vw;
    align-items: center;
  }
  /deep/ .van-tabs__nav--card .van-tab.van-tab--active {
    background: #ffc107;
    color: #333;
  }
  /deep/ .van-tabs--card > .van-tabs__wrap {
    height: 45px;
  }
  /deep/ .van-tabs__nav--card {
    height: 45px;
    border-color: #ffc107;
    border-radius: 2vw 2vw 0 0;
    overflow: hidden;
    margin: 0;
  }
  /deep/ .van-tabs__nav--card .van-tab {
    border-right-color: #ffc107;
  }
  /deep/ .van-tabs__nav--card .van-tab {
    color:  #ffc107;
  }
  /deep/ .van-tabs__line {
    background-color: #ffc107;
  }
</style>
